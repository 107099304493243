import {
  Badge,
  FillButton,
  FlexBox,
  StrokeButton,
  Text,
} from '@codecademy/gamut';
import { Background, BackgroundProps } from '@codecademy/gamut-styles';

import { planData } from './consts';
import { PlanCardPricing } from './PlanCardPricing';

// export all the render functions as we need them in the desktop version as well
export const renderPlanCardDetails = (plan: (typeof planData)[number]) => (
  <>
    <Badge variant="tertiary" my={16}>
      {plan.badge}
    </Badge>
    <Text fontWeight="normal" textAlign="center" mb={16}>
      {plan.description}
    </Text>
  </>
);

export const renderPlanCardCtas = (plan: (typeof planData)[number]) => (
  <FlexBox column mt={24} gap={12} alignItems="center">
    {plan.ctas.reduce((acc, cta, i) => {
      if (i > 0) {
        acc.push(
          <Text key={`${cta.label} or`} fontWeight="normal">
            or
          </Text>
        );
      }
      if (cta.variant === 'stroke') {
        acc.push(
          <StrokeButton key={cta.label} href={cta.href} onClick={cta.onClick}>
            {cta.label}
          </StrokeButton>
        );
      }
      if (cta.variant === 'fill') {
        acc.push(
          <FillButton key={cta.label} href={cta.href} onClick={cta.onClick}>
            {cta.label}
          </FillButton>
        );
      }
      return acc;
    }, [] as React.ReactNode[])}
  </FlexBox>
);

export const PlanCard: React.FC<
  Partial<BackgroundProps> & {
    plan: (typeof planData)[number];
  }
> = ({ plan, ...backgroundProps }) => {
  return (
    <Background
      p={24}
      border={1}
      borderColor="border-primary"
      bg={plan.bg as BackgroundProps['bg']}
      {...backgroundProps}
    >
      <FlexBox column alignItems="center">
        <Text variant="title-md">{plan.title}</Text>
        {renderPlanCardDetails(plan)}
        <PlanCardPricing plan={plan} />
        {renderPlanCardCtas(plan)}
      </FlexBox>
    </Background>
  );
};
