import { Currency } from '@mono/data/codegen/monolith';
import { useUser } from '@mono/data/user';

import { AllCurrency, CFBCurrency } from '~/typings/payment';

export interface CurrencyDetails {
  currency: AllCurrency;
  price: number;
}

export const CFBCurrencyList: Record<string, CurrencyDetails> = {
  US: { currency: Currency.Usd, price: 299 },
  IN: { currency: Currency.Inr, price: 24338 },
  AU: { currency: Currency.Aud, price: 443 },
  CA: { currency: Currency.Cad, price: 398 },
  GB: { currency: Currency.Gbp, price: 225 },
  EU: { currency: Currency.Eur, price: 270 },
  SE: { currency: Currency.Sek, price: 3133 },
  NO: { currency: Currency.Nok, price: 2988 },
  PL: { currency: Currency.Pln, price: 1351 },
  DK: { currency: CFBCurrency.Dkk, price: 2138 },
  BR: { currency: CFBCurrency.Brl, price: 1614 },
  BG: { currency: CFBCurrency.Bgn, price: 562 },
  CH: { currency: CFBCurrency.Chf, price: 282 },
  CZ: { currency: CFBCurrency.Czk, price: 7006 },
  HU: { currency: CFBCurrency.Huf, price: 117810 },
  MX: { currency: CFBCurrency.Mxn, price: 5778 },
  RO: { currency: CFBCurrency.Ron, price: 1415 },
};

const euCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
];

export const useLocalizedCurrency = () => {
  const user = useUser();
  const geoCountry = user?.location?.geo_country || 'US';

  if (euCountries.includes(geoCountry)) {
    return CFBCurrencyList.EU;
  }

  // ensure that we only return a country that we support
  if (CFBCurrencyList[geoCountry]) {
    return CFBCurrencyList[geoCountry];
  }

  return CFBCurrencyList.US;
};

export const useUserGeoLocation = () => {
  const user = useUser();
  return user?.location?.geo_country || 'US';
};
