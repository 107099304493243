export type VerificationResponse = {
  currentStep: string;
  errorIds: string[];
};

export type SheerIdForm = {
  on: (event: string, callback: (data: VerificationResponse) => void) => void;
  once: (event: string, callback: (data: VerificationResponse) => void) => void;
  setViewModel: (data: object) => void;
  setOptions: (data: object) => void;
};

export type SheerId = {
  loadInModal(url: string): SheerIdForm;
  loadInlineIframe(container: HTMLElement, url: string): SheerIdForm;
};

const cdnUrl = 'https://cdn.jsdelivr.net/npm/@sheerid/jslib@2/sheerid-install';

let sheerIdPromise: Promise<SheerId>;

export function initializeSheerId(): Promise<SheerId> {
  if (!sheerIdPromise) {
    sheerIdPromise = (async () => {
      try {
        const _module = await import(/* webpackIgnore: true */ `${cdnUrl}.js`);
        return _module.default as SheerId;
      } catch (error) {
        throw new Error('Failed to load SheerID.');
      }
    })();
    const styles = document.createElement('link');
    Object.assign(styles, {
      rel: 'stylesheet',
      type: 'text/css',
      href: `${cdnUrl}.css`,
      crossOrigin: 'anonymous',
    });
    document.head.append(styles);
  }
  return sheerIdPromise;
}

export async function triggerSheerIdModal(programId: string) {
  const sheerId = await initializeSheerId();
  const modal = sheerId.loadInModal(
    `https://services.sheerid.com/verify/${programId}/`
  );
  modal.setOptions({ logLevel: 'info' });
}
