import { GetServerSidePropsContext } from 'next';

import { getCountryCodeFromReq } from '~/libs/getCountryCodeFromReq';

export const LOCALE_PROP_NAME = '__LOCALE__';

export type PagePropsWithLocale = {
  [LOCALE_PROP_NAME]: {
    locale: string;
  };
};

export const exportLocaleAsProps = (
  context: GetServerSidePropsContext
): PagePropsWithLocale => {
  const countryCode = getCountryCodeFromReq(context.req);
  const locale = Array.isArray(countryCode)
    ? countryCode[0]
    : countryCode || '';

  return {
    [LOCALE_PROP_NAME]: {
      locale,
    },
  };
};

export const useServerLocale = (pageProps: PagePropsWithLocale) =>
  pageProps?.[LOCALE_PROP_NAME]?.locale;
