export type HighlightSegment = {
  value: string;
  highlight: boolean;
};

export type AutocompleteSuggestion = {
  title: string;
  segments: HighlightSegment[];
};

export type SearchAsYouTypeResults = {
  query: string;
  top: {
    type: string;
    title: string;
    slug: string;
    contentId?: string;
    urlPath: string;
    key: string;
    segments: HighlightSegment[];
  }[];
  queryLoadTime: number;
  searchId: string;
  resultsCount: number;
};

export enum SearchAction {
  Autocomplete = 'autocomplete',
  SearchAsYouType = 'search-as-you-type',
}

export type SearchResultTypes = {
  [SearchAction.Autocomplete]: AutocompleteSuggestion[];
  [SearchAction.SearchAsYouType]: SearchAsYouTypeResults;
};

export type SearchWorker = <A extends SearchAction>(
  action: A,
  query: string
) => Promise<SearchResultTypes[A]>;
