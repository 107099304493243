import { FillButton, Modal, TextButton } from '@codecademy/gamut';
import { PastDueModalContents } from '@mono/brand';
import { LoggedInUserResponseData } from '@mono/data/user';
import { getBillingPath } from '@mono/util/url';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-use';

import { trackUserClick } from '~/libs/tracking';

import { OnetimePastDueDunningModal } from '../OnetimeDunning';

export interface PastDueModalProps {
  user: LoggedInUserResponseData;
  isPaypal: boolean;
  isPastDue: boolean;
  pastDueErrCode: string;
  provider?: string | null;
  paymentLink?: string | null;
  cancellationLink?: string | null;
  currentRenewalDate?: string | null;
}

export const PastDueModal: React.FC<
  React.PropsWithChildren<PastDueModalProps>
> = ({
  isPaypal,
  isPastDue,
  pastDueErrCode,
  user,
  provider,
  currentRenewalDate,
  paymentLink,
  cancellationLink,
}) => {
  const isSilverPlan = user.isPlusUser;
  const location = useLocation();
  const currentPath = location.pathname || '';

  const canViewModal = useMemo(() => {
    return (
      !user.isSkillsoftUser &&
      !user.isBusinessUser &&
      isPastDue &&
      !!pastDueErrCode &&
      !currentPath.includes('/checkout')
    );
  }, [
    user.isSkillsoftUser,
    user.isBusinessUser,
    isPastDue,
    pastDueErrCode,
    currentPath,
  ]);

  const [showPastDueModal, setPastDueModal] = useState(true);

  const trackClick = () =>
    trackUserClick({
      target: 'past_due_modal_update',
      page_name: 'past_due_modal',
    });

  const onClose = () => {
    setPastDueModal(false);
  };

  const closeButton = (
    <TextButton onClick={onClose} mr={16}>
      Close
    </TextButton>
  );

  const updatePaymentButton = (
    <FillButton onClick={trackClick} href={getBillingPath({ showModal: true })}>
      Update payment
    </FillButton>
  );

  // if provider is onetime we display a different modal with renew button
  if (provider === 'onetime')
    return (
      <OnetimePastDueDunningModal
        currentRenewalDate={currentRenewalDate ?? ''}
        isPro={!isSilverPlan}
        paymentLink={paymentLink ?? ''}
        cancellationLink={cancellationLink ?? ''}
        canViewModal={canViewModal}
      />
    );

  return (
    <Modal
      isOpen={canViewModal && showPastDueModal}
      size="medium"
      onRequestClose={onClose}
      title="Update your payment method"
    >
      <PastDueModalContents
        pastDueErrorCode={pastDueErrCode}
        isPaypalPayment={isPaypal}
        isSilverPlan={isSilverPlan}
        closeButton={closeButton}
        updatePaymentButton={updatePaymentButton}
      />
    </Modal>
  );
};
