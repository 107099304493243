export enum FeatureFlag {
  AI_ASSISTANT_STATIC_PROMPTS = 'ai_assistant_static_prompts',
  AI_CUSTOM_PROJECT = 'ai_custom_project',
  AI_FREE_RESPONSE_QUESTION = 'ai_free_response_question',
  BEHAVIORAL_INTERVIEW = 'behavioral_interview',
  BUSINESS_RAISE_INVOICE_OPTION = 'business_raise_invoice_option',
  COHORTS_INTERSTITIAL = 'cohorts_interstitial',
  CONCEPTS_IN_SKILLS = 'concepts_in_skills',
  CYCLE_G_CERT_PATH_MERCHANDISING = 'cycle_g_cert_path_merchandising',
  DASHBOARD_SMART_PRACTICE = 'dashboard_smart_practice',
  ENTERPRISE_GLOBAL_NAV = 'enterprise_global_nav',
  GOLD_EARLY_ACCESS = 'gold_early_access',
  POST_QUIZ_REVIEW = 'ai_post_quiz_review',
  PRACTICE_EXPERIENCE = 'practice_xp',
  RELEASE_EMODULE_PAGES = 'release_emodule_pages',
  RESPONSIVE_LE = 'responsive_le',
  STRIPE_AFTERPAY_CHECKOUT = 'stripe_afterpay_checkout',
  WEEKLY_SYLLABUS = 'weekly_syllabus',
  XP_LEADERBOARD_INTERSTITIAL = 'xp_leaderboard_interstitial',
}
