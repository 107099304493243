import {
  Anchor,
  FlexBox,
  LayoutGrid,
  Text,
  WithChildrenProp,
} from '@codecademy/gamut';
import { ArrowChevronDownFilledIcon } from '@codecademy/gamut-icons';
import {
  Colors,
  css,
  pxRem,
  states,
  useCurrentMode,
} from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';
import styled from '@emotion/styled';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import * as React from 'react';

export const appHeaderMobileBreakpoint = 'lg' as const;

export interface AnimatedHeaderZoneProps extends WithChildrenProp {
  visible?: boolean;
}

const animatedPopoverVariants: Variants = {
  enter: { opacity: 1, transition: { duration: 0.2 } },
  exit: { opacity: 0, transition: { duration: 0.2 } },
};

const borderColor = 'border-primary';

export const useMegaMenuHeaderResponsiveStyles = (): {
  bg: Colors;
  borderColor: Colors;
  color: Colors;
} => {
  const mode = useCurrentMode();

  const bg = mode === 'dark' ? 'white' : 'navy-800';
  const color = mode === 'dark' ? 'navy-900' : 'blue-0';

  return { bg, borderColor: color, color };
};

export const AnimatedHeaderZone: React.FC<AnimatedHeaderZoneProps> = ({
  children,
  visible,
}) => {
  return visible ? (
    <AnimatePresence>
      <motion.div
        animate="enter"
        exit="exit"
        initial="exit"
        variants={animatedPopoverVariants}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  ) : null;
};

export const DropdownAnchor = styled(Anchor)(
  css({
    alignItems: `center`,
    display: `flex`,
    padding: `0.5rem 0`,
    textAlign: `center`,
    whiteSpace: `nowrap`,

    '&:focus::before': {
      opacity: 1,
    },
  })
);

const dropdownStates = states({
  open: {
    transform: `rotate(-180deg)`,
  },
});

export const DropdownIcon = styled(ArrowChevronDownFilledIcon)<
  StyleProps<typeof dropdownStates>
>(
  css({
    marginLeft: pxRem(5),
    transition: `transform 0.35s ease-out`,
    transformOrigin: `center ${pxRem(5)}`,
  }),
  states({
    open: {
      transform: `rotate(-180deg)`,
    },
  })
);

export const StyledText = styled(Text)(
  css({
    '&::after': {
      display: `block`,
      content: `attr(title)`,
      fontWeight: `bold`,
      height: `1px`,
      color: `transparent`,
      overflow: `hidden`,
      visibility: `hidden`,
    },
  })
);

export const StyledDropdown = styled(motion.div)(
  css({
    bg: `background`,
    borderColor,
    borderStyle: `solid`,
    overflow: `hidden`,
    position: `absolute`,
  })
);

export const LayoutGridAntiAliased = styled(LayoutGrid)`
  -webkit-font-smoothing: antialiased;
`;

/* for Resources & Catalog menus */
export const DescriptionSectionContainer = styled(FlexBox)(
  css({
    '&:focus-visible': {
      color: 'text',
      outline: '1px solid currentColor !important',
    },
  })
);
