import { isOnBrowser } from '@mono/util/env';

import { AnonymousUser } from './types';

export type GlobalUserMeta = {
  experiments?: AnonymousUser['experiments'];
};

export const exposeUserMeta = (user: AnonymousUser | undefined) => {
  const meta: GlobalUserMeta = {};
  if (user?.experiments) meta.experiments = user.experiments;

  /**
   * The `any` type is used here to avoid needing to add this to the global window type
   * since it is never meant to be referenced in code, this shouldn't be an issue
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (isOnBrowser()) (window as any).__CC_TRACKING_META__ = meta;
};
