import { Anchor, Text } from '@codecademy/gamut';
import styled from '@emotion/styled';
import { Loading } from '@mono/brand';
import { getJobPath } from '@mono/util/url';
import groupBy from 'lodash/groupBy';
import React, { useEffect, useState } from 'react';

import { DepartmentJobs } from './DepartmentJobs';
import { fetchJobOpenings } from './fetchJobOpenings';
import { withSortedKeys } from './libs';
import { GreenhouseJob } from './types';

export const errorMessage =
  'We had some trouble loading this content. Please refresh your browser or try again later.';

const List = styled.ul`
  max-width: 750px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 2rem 0 0;
  padding: 0;
`;

export const OpenPositions: React.FC = () => {
  const [status, setStatus] = useState<'loading' | 'ready' | 'error'>(
    'loading'
  );

  const [greenhouseJobs, setGreenhouseJobs] = useState<GreenhouseJob[]>();

  useEffect(() => {
    fetchJobOpenings()
      .then((response) => {
        setGreenhouseJobs(response?.jobs);
        setStatus('ready');
      })
      .catch(() => {
        setStatus('error');
      });
  }, []);

  let jobsByDepartment: { [key: string]: GreenhouseJob[] } = {};
  let jobsWithoutDepartment: GreenhouseJob[] = [];

  if (status === 'ready') {
    jobsWithoutDepartment =
      greenhouseJobs?.filter((j) => j.departments.length === 0) || [];
    jobsByDepartment = withSortedKeys<GreenhouseJob[]>(
      groupBy(
        greenhouseJobs?.filter((j) => j.departments.length > 0) || [],
        (job) => job.departments[0].name
      )
    );
  }

  return (
    <div>
      <Text as="h2" fontSize={{ _: 26, lg: 34 }}>
        Open Positions
      </Text>
      {status === 'loading' && <Loading />}
      {status === 'error' && <Text>{errorMessage}</Text>}
      {status === 'ready' && (
        <List>
          {Object.entries(jobsByDepartment).map(([dept, jobs]) => (
            <ListItem key={dept}>
              <DepartmentJobs dept={dept} jobs={jobs} />
            </ListItem>
          ))}
          {/* We want to display job without a department, below the accordion by themselves. */}
          {jobsWithoutDepartment.length > 0 && (
            <ListItem key="General">
              {jobsWithoutDepartment.map((job) => (
                <Anchor
                  key={job.id}
                  variant="standard"
                  href={getJobPath(job.id)}
                  display="block"
                >
                  {job.title}
                </Anchor>
              ))}
            </ListItem>
          )}
        </List>
      )}
    </div>
  );
};
