import { partytownSnippet } from '@qwik.dev/partytown/integration';

import { partytownConfig } from './config';
import { gtmDoubleFrame } from './gtmDoubleFrame';

// Encapsulate to avoid collision of global vars aliased in minification.
const encapsulate = (js: string) => `(() => {${js}})();`;

export function initializePartytown() {
  const ptScript = document.createElement('script');
  ptScript.innerHTML = encapsulate(partytownSnippet(partytownConfig()));
  document.head.appendChild(ptScript);
  new BroadcastChannel('gtm-iframe').onmessage = ({ data }) => {
    gtmDoubleFrame(data);
  };

  /*
   * Img elements added by GTM tags for Google Ads normally set themselves as
   * position:absolute. Since Partytown prevents this, a stylesheet is added to
   * restore the style (and remove the added whitespace).
   */
  const styles = document.createElement('style');
  styles.innerText = `body > img[aria-hidden=true] { position: absolute; }`;
  document.head.appendChild(styles);
}
