import { Box } from '@codecademy/gamut';

import { PricingTableDesktop } from './PricingTableDesktop';
import { PricingTableMobile } from './PricingTableMobile';

export const PricingTable: React.FC = () => {
  return (
    <>
      <Box display={{ _: 'none', md: 'block' }}>
        <PricingTableDesktop />
      </Box>
      <Box display={{ md: 'none' }}>
        <PricingTableMobile />
      </Box>
    </>
  );
};
