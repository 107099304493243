import { createContext, ReactNode, useContext, useEffect } from 'react';
import useSWR from 'swr';

import { exposeUserMeta } from './exposeUserMeta';
import { fetchUser, userDataEndpoint, userIsLoggedIn } from './fetchUser';
import type { UserResponseData } from './types';

type CurrentUserState = UserResponseData | undefined;

type UserContext = {
  currentUser: CurrentUserState;
  isLoading: boolean;
  error: Error | undefined;
  /**
   * Forces user data to be updated
   */
  revalidate: () => void;
};

const UserContext = createContext<UserContext>({
  currentUser: undefined,
  isLoading: false,
  error: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  revalidate: () => {},
});

type UserProviderProps = {
  children: ReactNode;
};

export function UserProvider({ children }: UserProviderProps) {
  const { data, isLoading, error, mutate } = useSWR<UserResponseData>(
    userDataEndpoint,
    fetchUser,
    {
      dedupingInterval: 1000 * 10, // 10 seconds
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      exposeUserMeta(data);
    }
  }, [data]);

  const contextValue: UserContext = {
    currentUser: data,
    isLoading,
    revalidate: () => mutate(),
    error,
  };
  return (
    <>
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
    </>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

export function useUser() {
  const context = useUserContext();
  return context.currentUser;
}

export function useUserId() {
  const user = useUser();
  return user && userIsLoggedIn(user) ? user?.id : undefined;
}

export function useUserJwt() {
  const user = useUser();
  return user && userIsLoggedIn(user) ? user?.jwt : undefined;
}

export const useAdmin = () => {
  const user = useUser();
  return user && userIsLoggedIn(user) && user?.roles?.admin;
};

export const useSkillsoftUser = () => {
  const user = useUser();
  return Boolean(user && userIsLoggedIn(user) && user.isSkillsoftUser);
};

export const useUserAppearance = () => {
  const user = useUser();

  if (!user || !userIsLoggedIn(user)) return undefined;
  return user?.appearance;
};
