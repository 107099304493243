import { logAndReturnError } from '@mono/data/logging';

import type { AnonymousUser, LoggedInUser, UserResponseData } from './types';

export function userIsLoggedIn<
  L extends LoggedInUser = LoggedInUser,
  A extends AnonymousUser = AnonymousUser
>(user: A | L): user is L {
  return 'jwt' in user;
}

export const userDataEndpoint =
  process.env.NEXT_PUBLIC_USER_DATA_URL ?? `/users/web`;

export const fetchUser = async () => {
  const res = await fetch(userDataEndpoint, {
    credentials: 'include',
    mode: 'cors',
  });
  if (!res.ok) {
    const msg = (await res.json()) as string;
    const err = new Error(`Failed to fetch user data: ${msg}`);
    throw logAndReturnError(err);
  }

  return res.json() as Promise<UserResponseData>;
};
